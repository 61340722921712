import { SetupContext, ref, computed } from '@vue/composition-api';
import { AccountUUID } from '@ax/api-clients-accounts/models';
import { User } from '@ax/data-services-user/models/user';
import { Domain, DomainUUID } from '@/models/authentication';
import { deleteDomain, getDomains } from '@/services/auth/domains.service';
import { DataTableState, DataTablePageState } from '@/utils/data-table-state';
import { DEFAULT_TABLE_PAGINATION } from '@/utils/constants';
import { QueryStateParametersConfiguration } from '@/utils/query-state';
import { QueryStateStorageConfig } from '@/utils/storage';
import { USER_GET_PROFILE } from '@/store/actions/user';
import { useRouter } from '@ax/vue-utils/router';

interface DomainsQueryState extends DataTablePageState<Domain> {
  search?: string;
}

export function getDomainQueryStateConfiguration(): QueryStateParametersConfiguration<{}> {
  return {
    // Limit is being manually overridden to prevent pagination
    limit: { defaultValue: DEFAULT_TABLE_PAGINATION, storeInBrowser: true },
    page: { defaultValue: 1 },
    search: { defaultValue: '' },
    sort: { defaultValue: 'name:desc' },
  };
}

export function useDomainsTable(
  context: SetupContext,
  queryStateParametersConfiguration?: QueryStateParametersConfiguration<
    DataTablePageState<Domain>
  >,
) {
  const router = useRouter();
  const user = computed<User>(
    () => context.root.$store.getters[USER_GET_PROFILE],
  );
  const domains = ref<Domain[]>([]);
  const totalServerDomains = ref<number>();
  const queryStateConfig =
    queryStateParametersConfiguration || getDomainQueryStateConfiguration();
  const storageConfig: QueryStateStorageConfig = {
    key: 'ax-domains-prefs',
    store: localStorage,
  };

  function getDomainsList(query: string): Promise<void> {
    const queryParams = new URLSearchParams(query);

    return getDomains(
      user.value.account_id as string, // safe to assume this will be present
      {
        ...Object.fromEntries(queryParams),
        // pagination essentially disabled for domains for the time being
        limit: 500,
      },
    ).then(({ data, metadata }) => {
      domains.value = data;
      totalServerDomains.value = metadata.total_count;
    });
  }

  const { tableState, queryState, updateQueryState } =
    DataTableState.synchronize<Domain, DomainsQueryState>(
      router.currentRoute,
      router,
      queryStateConfig,
      {
        callback: ({ apiQuery }) => getDomainsList(apiQuery),
        storageConfig,
      },
    );

  async function handleDeleteDomain(
    accountId: AccountUUID,
    domainId: DomainUUID,
  ) {
    try {
      await deleteDomain(accountId, domainId);
      domains.value = domains.value.filter(({ id }) => id !== domainId);
    } finally {
      // make eslint happy...
    }
  }

  return {
    domains,
    deleteDomain: handleDeleteDomain,
    totalServerDomains,
    tableState,
    queryState,
    updateQueryState,
    storageConfig,
  };
}
